import React, { PureComponent } from 'react';
import { convertRouterStory } from '@/utils/routerConvert';
import { connect } from 'react-redux';

let timer = null;
@connect(({ actions }) => ({
  actions,
}))
class SearchStories extends PureComponent {
  state = {
    valueSearch: '',
    searchArr: [],
  };

  componentWillUnmount() {
    if (timer) clearTimeout(timer);
  }

  handleChange = e => {
    const { value } = e.target;
    const { dispatch } = this.props;
    this.setState({
      valueSearch: value.trim(),
    });
    if (timer) clearTimeout(timer);
    if (!value.trim()) {
      this.setState({
        searchArr: [],
      });
      return;
    }
    timer = setTimeout(() => {
      const param = {
        filter: JSON.stringify({
          status: 1,
          searchString: value.trim(),
        }),
        range: JSON.stringify([0, 50 - 1]),
        sort: JSON.stringify(['id', 'asc']),
        // attributes: `id,name,status`,
      };
      dispatch({
        type: 'webs/fetchStories',
        payload: param,
        callback: res => {
          if (res?.success) {
            this.setState({
              searchArr: res?.result?.list || [],
            });
          }
        },
      });
    }, 200);
  };

  render() {
    const { visibleSearchMobile, onCancel } = this.props;
    const { searchArr } = this.state;
    return (
      <div id="searchPopup" className={`search-popup ${visibleSearchMobile ? '' : 'hidden'}`}>
        <header className="header">
          <div id="searchForm" className="search-form flex-b-c">
            <div className="search-area search-box flex-a-c">
              <i className="fas fa-search" />
              <input
                type="text"
                id="key-search"
                name="key"
                className="form-control text-center ui-autocomplete-input"
                placeholder="Tìm kiếm"
                onChange={this.handleChange}
                autoComplete="off"
                style={{ width: '100%', outline: 'none' }}
              />
            </div>
            <a className="search-cancel" onClick={onCancel}>
              <i className="fas fa-bars" />
            </a>
          </div>
        </header>
        {searchArr?.length > 0 && (
          <div id="searchList" className="search-list">
            <ul className="search-list-ul ui-menu" id="searchList-ul">
              {searchArr?.map(item => (
                <li className="ui-menu-item" role="presentation" key={item.id}>
                  <a
                    href={convertRouterStory(item.name, item.id)}
                    id="ui-id-2"
                    className="ui-corner-all flex-b-c"
                    tabIndex={-1}
                  >
                    <div>
                      <strong>{item.name}</strong>
                      <span>
                        {item.storyAuthors?.name}&nbsp; {item.countChapter} chương
                      </span>
                    </div>

                    <i className="fas fa-angle-right" aria-hidden="true" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }
}

export default SearchStories;

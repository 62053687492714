/* eslint-disable import/extensions */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Input, Modal, notification, Form, Icon } from 'antd';
import { /* defineMessages, */ injectIntl, FormattedMessage } from 'react-intl';
import _ from 'lodash';
import moment from 'moment';
import getConfig from 'next/config';
import RegistersPage from './UsersRegister';
import RequestForgetPass from './RequestForgetPass';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

const jwt = require('jsonwebtoken');
const { publicRuntimeConfig } = getConfig();

@connect(({ login }) => ({
  login,
}))
@Form.create()
@injectIntl
class Index extends PureComponent {

  state = {
    visibleDrawer: false,
    rememberMe: true,
    visibleModal2: false,
    visibleModal: false,
    help: '',
    status: '',
  };

  // componentDidMount() {
  //   document.getElementById('userName').select();
  // }

  componentWillReceiveProps(nextProps) {
    const { visible } = this.props;
    if (nextProps.visible !== visible) {
      this.setState(
        {
          visibleDrawer: true,
        }
      );
    }
  }

  handleClose = () => {
    this.setState({
      visibleDrawer: false,
    });
  };

  // xử lý sự kiện onchange Checkbox
  changeRememberMe = () => {
    const { rememberMe } = this.state;
    this.setState({ rememberMe: !rememberMe });
  };

  // Login
  handleSubmit = e => {
    const { dispatch, intl } = this.props;
    e.preventDefault();
    const { form } = this.props;
    const loginFail = sessionStorage.getItem('loginFail');
    let loginFailJson = [];
    if (loginFail) {
      loginFailJson = JSON.parse(loginFail);
      _.orderBy(loginFailJson, ['dateCreated'], ['desc']);
      // Nếu login thất bại 3 lần liên tiếp thì đợi 5 phút sau login lại
      if (loginFailJson && loginFailJson.length > 0 && loginFailJson.length === 3) {
        const min = moment().diff(loginFailJson[0].dateCreated, 'minutes');
        if (min >= 5) {
          sessionStorage.removeItem('loginFail');
          loginFailJson = [];
        }
        else {
          const msgFail = intl.formatMessage({ id: 'app.login.loginFail' });
          const msgFail1 = intl.formatMessage({ id: 'app.login.loginFail.try' });
          notification.warning({
            message: (
              <>
                <p>{msgFail}</p> <em>{msgFail1}</em>
              </>
            ),
            placement: 'bottomRight',
            style: { background: '#fffbe6' },
          });
        }
      }
    }
    // Xử lý login
    if (loginFail === null || (loginFailJson && loginFailJson.length <= 2)) {
      form.validateFields(['userName', 'password'], (err, values) => {
        if (!values.password) {
          this.setState({
            help: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
            status: 'error',
          });
        }
        if (!err && values.password) {
          dispatch({
            type: 'login/login',
            payload: {
              ...values,
              userName: values.userName && values.userName.trim(),
              // eslint-disable-next-line react/destructuring-assignment
              rememberMe: this.state.rememberMe,
            },
            callback: result => {
              // console.log("result ", result);
              // Nếu login thất bại
              if (
                result &&
                (result.status === 'error' || result.error || result.success === false)
              ) {
                // Lưu vào sessionStorage lần login thất bại
                loginFailJson.push({ dateCreated: new Date() });
                sessionStorage.setItem('loginFail', JSON.stringify(loginFailJson));
                if (loginFailJson.length >= 3) {
                  // Khi login thất bại nhưng không quá 3 lần
                  const msgFail = intl.formatMessage({ id: 'app.login.loginFail' });
                  const msgFail1 = intl.formatMessage({ id: 'app.login.loginFail.try' });
                  notification.warning({
                    message: (
                      <>
                        <p>{msgFail}</p> <em>{msgFail1}</em>
                      </>
                    ),
                    placement: 'bottomRight',
                    style: { background: '#fffbe6' },
                  });
                  form.resetFields(['password']);
                  return
                }
                if (result && result.message) {
                  notification.error({
                    message: result && result.error.message,
                    placement: 'bottomRight',
                    style: { background: '#fff1f0', fontWeight: 400 },
                  });
                  form.resetFields(['password']);
                  // Nếu là lỗi server
                } else if (result && result.error) {
                  notification.error({
                    message: result && result.error.message,
                    placement: 'bottomRight',
                    style: { background: '#fff1f0', fontWeight: 400 },
                  });
                  form.resetFields(['password']);
                }
              } else if (
                result &&
                (result.status === 'ok' || result.error || result.success === true)
              ) {
                // Nếu login thành công
                sessionStorage.removeItem('loginFail');
              }
            },
          });
        }
      });
    }
  };

  onCreateTokenLogin = async record => {
    const { dispatch } = this.props;
    const token = jwt.sign(record, publicRuntimeConfig.JWT_SECRET)
    dispatch({
      type: 'login/loginSocial',
      payload: {
        token
      },
      callback: result => {
        if (
          result &&
          (result.status === 'ok' || result.error || result.success === true)
        ) {
          // Nếu login thành công
          sessionStorage.removeItem('loginFail');
        }
      },
    });
  };

  handleLogin = (resp, type) => {
    // this.resetCookies();
    // const objectP = Cookies.get('object');
    if (type === 'facebook' && resp?.accessToken) {
      // console.log(resp);
      fetch(
        `https://graph.facebook.com/me?access_token=${resp?.accessToken}&fields=id,name,picture`,
      ).then(response => response.json()).then(data => {
        const params = {
          referralSocial: `${type}`,
          image: {
            file:
              data.referralSocial === 'facebook'
                ? data?.picture?.data?.url
                : data?.picture,
            extension: '',
          },
          name: data?.name,
          idSocial: data.id,
        };
        this.onCreateTokenLogin(params);
      })
    }
    else if (type === 'google' && resp?.profileObj) {
      const params = {
        referralSocial: `${type}`,
        image: { file: resp?.profileObj?.imageUrl, extension: '' },
        name: resp?.profileObj?.name,
        idSocial: resp?.profileObj?.googleId
      };
      this.onCreateTokenLogin(params);
    }
  };

  // Kiểm tra caplock có đang bật hay không
  checkCapsLock = e => {
    const { intl } = this.props;
    const caps_lock_on = e.getModifierState('CapsLock');
    if (caps_lock_on === true)
      this.setState({
        help: intl.formatMessage({ id: 'app.login.validate.capsLock' }),
        status: 'warning',
      });
    else
      this.setState({
        help: '',
        status: '',
      });
  };

  // Validate ô input mật khẩu
  checkRequired = e => {
    const { intl } = this.props;
    const { value } = e.target;
    if (!value) {
      this.setState({
        help: intl.formatMessage({ id: 'app.common.crud.validate.input' }),
        status: 'error',
      });
    }
  };


  render() {
    const {
      intl,
      form: { getFieldDecorator },
    } = this.props;
    const { visibleDrawer, keyChildren, help, status, visibleModal2, visibleModal } = this.state;
    // console.log(visibleDrawer);
    return (

      <Modal
        key={keyChildren}
        visible={visibleDrawer}
        onCancel={() => {
          this.setState({ visibleDrawer: false });
        }}
        footer={[]}
      >
        <div className="wrap-login100 p-l-55 p-r-55 p-t-65 p-b-54">
          <Form id="login-form" className="login100-form validate-form">
            <span className="login100-form-title p-b-49">
              Đăng nhập
            </span>
            <span className="label-input100">Tên đăng nhập:</span>
            <Form.Item className='wrap-input100'>
              {getFieldDecorator('userName', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'app.common.crud.validate.input',
                    }),
                  },
                  {
                    min: 6,
                    message: 'Tên đăng nhập không được nhỏ hơn 6 ký tự',
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="user" />
                  }
                  placeholder={intl.formatMessage({
                    id: 'app.login.userName',
                  })}
                  className="input100"
                />
              )}
            </Form.Item>
            <span className="label-input100">Mật khẩu:</span>
            <Form.Item help={help} validateStatus={status} className='wrap-input100'>
              {getFieldDecorator('password', {
                rules: [
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'app.common.crud.validate.input',
                    }),
                  },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="lock" />
                  }
                  type="password"
                  placeholder={intl.formatMessage({
                    id: 'app.login.password',
                  })}
                  className="input100"
                  onKeyPress={e => this.checkCapsLock(e)}
                  onChange={e => this.checkRequired(e)}
                />
              )}
            </Form.Item>
            <div className="bottom_from">
              <a
                className="bottom_from_1"
                onClick={() => {
                  this.setState({
                    visibleModal: !visibleModal,
                  });
                }}
              >
                Đăng ký
              </a>
              <a
                className="bottom_from_2"
                onClick={() => {
                  this.setState({
                    visibleModal2: !visibleModal2,
                  });
                }}
              >
                Quên mật khẩu?
              </a>
            </div>
            <div className="container-login100-form-btn">
              <div className="wrap-login100-form-btn">
                <div className="login100-form-bgbtn" />
                <button type="submit" onClick={this.handleSubmit} className="login100-form-btn">
                  đăng nhập
                </button>
              </div>
            </div>
            <div className="login-bars">
              <span className="bars" />
              <span className="text">or continue with</span>
              <span className="bars" />
            </div>
            <div className="login-btn-with">
              <GoogleLogin
                clientId={`${publicRuntimeConfig.GOOGLE_CLIENT_ID}`}
                render={renderProps => (
                  <button
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                    className="btn_custom google-btn"
                    type="button"
                  >
                    <img
                      src="/static/web/img/grommet-icons_google.svg"
                      alt="icons_google"
                    />
                    Đăng nhập bằng Google
                  </button>
                )}
                buttonText="Login"
                onSuccess={resp => this.handleLogin(resp, 'google')}
                onFailure={resp => console.log(`object`, resp)}
                cookiePolicy="single_host_origin"
              />
              <FacebookLogin
                appId={`${publicRuntimeConfig.FACEBOOK_APP_ID}`}
                fields="name,email,picture"
                callback={resp => this.handleLogin(resp, 'facebook')}
                authType="reauthenticate"
                render={renderProps => (
                  <button
                    className="btn_custom face-btn"
                    type="button"
                    onClick={renderProps.onClick}
                  >
                    <img src="/static/web/img/akar-icons_facebook-fill.svg" alt="face" />
                    <span>Đăng nhập bằng Facebook</span>
                  </button>
                )}
              />
            </div>
          </Form>
        </div>
        <RequestForgetPass {...this.props} visible={visibleModal2} />
        <RegistersPage visible={visibleModal} />
      </Modal>
    );
  }
}

export default Index;

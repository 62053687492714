import React from 'react';
import dynamic from 'next/dynamic';
import SearchStories from '@/componentWebs/SearchStories';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import ModalLogin from '@/componentWebs/ModalLogin';
import EncodeUrl from '@/utils/encode';
import { nameRouterSearch, adsPositionsId } from '@/utils/constants';
import { convertRouterStory } from '@/utils/routerConvert';
import Cookie from 'js-cookie';
import { renderLinkIcon } from '@/utils/utils'
import publicRuntimeConfig from '@/utils/config';
import $ from 'jquery'
import { isMobile } from 'react-device-detect';

let timer = null;

const OwlCarousel = dynamic(() => import(`@/componentWebs/Global/OwlCarousel`), {
  ssr: false,
  loading: () => null,
});
@connect(({ actions }) => ({
  actions,
}))
class Index extends React.Component {
  state = {
    visibleSearchMobile: false,
    visible: false,
    valueSearch: '',
    searchArr: [],
    slideData: []
  };

  componentDidMount() {
    const { dispatch } = this.props;
    const param = {
      filter: {
        status: '1',
        adsPositionsId: adsPositionsId,
      },
      range: [0, 4],
      // attributes: 'id,status,title,createDate',
    };
    dispatch({
      type: 'webs/fetchAllAd',
      payload: param,
      callback: res => {
        if (res?.success) {
          this.setState({
            slideData: res?.result?.list || []
          })
        }
      },
    });

  }

  componentWillUnmount() {
    if (timer) clearTimeout(timer);
  }

  logOut = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'login/logout',
      payload: '',
      callback: () => {
        window.location.href = '/';
      },
    });
  };

  handleChange = e => {
    const { value } = e.target;
    const { dispatch } = this.props;
    this.setState({
      valueSearch: value.trim(),
    });
    if (timer) clearTimeout(timer);
    if (!value.trim()) {
      this.setState({
        searchArr: [],
      });
      return;
    }
    timer = setTimeout(() => {
      const param = {
        filter: JSON.stringify({
          status: 1,
          searchString: value.trim(),
        }),
        range: JSON.stringify([0, 50 - 1]),
        sort: JSON.stringify(['id', 'asc']),
        // attributes: `id,name,status`,
      };
      dispatch({
        type: 'webs/fetchStories',
        payload: param,
        callback: res => {

          if (res?.success) {
            this.setState({
              searchArr: res?.result?.list || [],
            });
          }
        },
      });
    }, 200);
  };

  onKeyUp = e => {
    // console.log(e.which);
    // Enter
    if (e.which === 13) {
      // console.log('enter');
      this.handleSubmit();
    }
  };

  handleClickOrder = (item) => {
    if (item.id === '23') {
      const section = document.getElementById(`numero3-no12s1`);
      $('html, body').animate(
        {
          scrollTop: section.offsetTop - 50,
        },
        500
      );
    }
  }

  handleSubmit = e => {
    if (e) e.preventDefault();
    const { valueSearch } = this.state;
    if (!valueSearch) return;
    window.location.href = `/tim-kiem-tong-hop?${nameRouterSearch.nameSearch}=${valueSearch}`;
  };

  handleLogin = () => {
    const { currentUser } = this.props;
    const { visible } = this.state;

    const cookieToken = Cookie.get('token');
    if (cookieToken) {
      window.location.href = `/trang-ca-nhan/${EncodeUrl(currentUser.username)}`;
      return
    }

    if (isMobile) window.location.href = '/login'
    else {
      this.setState({
        visible: !visible
      })
    }



  }

  render() {
    const { visibleSearchMobile, visible, searchArr, slideData } = this.state;
    const { menuHeader, dataSite, currentUser } = this.props;
    // console.log(currentUser);
    return (
      <React.Fragment>
        <header>
          <div className="logo-wrap box-center h-mt" style={{ overflow: 'unset' }}>
            <div className="box-center cf">
              <div className="book-shelf fr">
                <a
                  onClick={() => {
                    if (!currentUser?.id) {
                      this.setState({ visible: !visible });
                    } else {
                      window.location.href = `/trang-ca-nhan/${EncodeUrl(currentUser.username)}`;
                    }
                  }}
                  id="numero2"
                >
                  <Icon type="user" />
                  <i className='user-name-header'>{currentUser?.id ? currentUser?.fullname : 'Tài khoản'}</i>
                </a>
                {currentUser?.id && (
                  <a onClick={this.logOut} title="Đăng xuất" id="logout-header">
                    <img src="https://truyen.tangthuvien.vn/images/app/182448.svg" />
                  </a>
                )}
              </div>

              <div className="logo fl">
                <a
                  href="/"
                  data-eid="qd_A11"
                  style={{
                    backgroundImage: `url(${publicRuntimeConfig.IMAGE_SERVER_NEW}${publicRuntimeConfig.IMAGE_PROJECT}${dataSite?.logo?.length > 0 && dataSite?.logo[0]?.file || ''}
                    )`,
                  }}
                />
                {/* ${getResponsiveImage(dataSite?.logo?.length > 0 && dataSite?.logo[0]?.file || '', [
                      220,
                      220,
                      220,
                    ])} */}
                {/* <div className='logo_title_header'>
                  <div className='logo_title'>{dataSite?.name}</div>
                </div> */}
              </div>
              <div className="search-wrap fl h-mt" style={{ position: 'relative' }}>
                <form id="formUrl" onSubmit={this.handleSubmit}>
                  <p>
                    <input
                      className="search-box ui-autocomplete-input"
                      id="inset-autocomplete-input"
                      name="kw"
                      type="text"
                      data-type="search"
                      placeholder="Nhập tên truyện hoặc tác giả.."
                      onChange={this.handleChange}
                      onKeyUp={this.onKeyUp}
                    />
                    <span
                      role="status"
                      aria-live="polite"
                      className="ui-helper-hidden-accessible"
                    />
                  </p>
                  <label
                    id="search-btn"
                    className="search-btn"
                    htmlFor="searchSubmit"
                    onClick={this.handleSubmit}
                  >
                    <em className="iconfont" data-eid="qd_A13">
                      <i className="fas fa-search" />
                    </em>
                  </label>
                </form>
                {searchArr?.length > 0 && (
                  <ul
                    className="ui-autocomplete ui-front ui-menu ui-widget ui-widget-content ui-corner-all"
                    id="ui-id-1"
                    tabIndex={0}
                    style={{
                      display: 'block',
                      position: 'absolute',
                      width: '100%',
                      top: '100%',
                      left: '0',
                      maxHeight: '60vh',
                      overflowY: 'auto',
                    }}
                  >
                    {searchArr?.map(item => (
                      <li className="ui-menu-item" role="presentation" key={item.id}>
                        <a
                          href={convertRouterStory(item.name, item.id)}
                          id="ui-id-2"
                          className="ui-corner-all"
                          tabIndex={-1}
                        >
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
          </div>
          <div className="main-nav-wrap h-mt">
            <div className="main-nav box-center cf">
              <ul>
                <li className="first">
                  <span>
                    <em>
                      <i />
                      <i />
                      <i />
                    </em>
                    Thể loại
                  </span>
                </li>
                {menuHeader?.map(item => (
                  <li className="nav-li" key={item.id}
                    onClick={() => this.handleClickOrder(item)}
                  >
                    <a href={item.id !== '23' ? item.url : '#'}>{item.name}</a>
                  </li>
                ))}
                <li className="game phone" id="game-phone">
                  <a href="/tai-app" target="_blank" data-eid="qd_A20"><b className="iconfont client"></b>Tải app</a>
                </li>

              </ul>
            </div>
          </div>

          <div className=" h-pc">
            <div className="header-mobile">
              <a href="/" className="m-logo">
                {dataSite.name}
              </a>

              <div className="pull-right">
                <a href='/tai-app' className="download_app"><img src="/static/truyen/images/app.png" width="55px" /></a>
                <a className="account" onClick={this.handleLogin}>
                  <i className="fas fa-user" />
                </a>
              </div>
            </div>

            <div className="home-slide">
              {slideData?.length > 0 && (
                <OwlCarousel
                  responsive={{
                    0: {
                      nav: false,
                      items: 1,
                      margin: 14,
                      dots: true,
                    },
                    425: {
                      nav: false,
                      items: 1,
                      margin: 14,
                      dots: true,
                    },
                    768: {
                      nav: false,
                      items: 1,
                      margin: 1,
                      dots: true,
                    },
                  }}
                  autoplay={true}
                  autoplayHoverPause={true}
                  autoplayTimeout={4000}
                  slideBy={1}
                  loop
                >
                  {slideData?.map(item => (
                    <a href={item.url}
                      key={item.id}
                    >
                      <img src={getResponsiveImage(item?.contents?.[0]?.file, [366, 366, 366])} width="100%" />{' '}
                    </a>
                  ))}
                </OwlCarousel>
              )}
            </div>

            <div className="search-box">
              <form action="#">
                <label
                  className="form-control text-center flex-center"
                  onClick={() =>
                    this.setState({
                      visibleSearchMobile: true,
                    })
                  }
                >
                  <i className="fas fa-search" />
                  Tìm kiếm
                </label>
              </form>
            </div>
            <SearchStories
              visibleSearchMobile={visibleSearchMobile}
              onCancel={() =>
                this.setState({
                  visibleSearchMobile: false,
                })
              }
            />

            <nav className="home-nav col-xs-12 home-crap flex-b-c">
              <a className="col-xs-3 text-center nav-category" href="/the-loai/tong-hop/0">
                {/* <i className="fas fa-home" /> */}
                <img src="/static/truyen/images/Thể loại.png" style={{ width: '25px' }} />
                <span>Thể loại</span>
              </a>
              {menuHeader?.map(item => (
                <a className="col-xs-3 text-center nav-trophy" href={item.url} key={item.id}>
                  <img
                    src={renderLinkIcon(item.icon)}
                    style={{ width: '25px', height: '25px', objectFit: 'cover' }}
                  />
                  <span>{item.name}</span>
                </a>
              ))}

              {/* <a className="col-xs-3 text-center nav-filter" href="/tim-kiem-tong-hop">
              <i className="fas fa-home" />
              <span>Bộ lọc</span>
            </a>
            <a className="col-xs-3 text-center nav-money" href="/top-users">
              <i className="fas fa-home" />
              <span>Top User</span>
            </a>
            <a className="col-xs-3 text-center nav-converter" href="/wall">
              <i className="fas fa-home" />
              <span>Review</span>
            </a> */}
            </nav>
          </div>
        </header>
        <ModalLogin visible={visible} {...this.props} />
      </React.Fragment>
    );
  }
}
export default Index;
